<template>
  <div class="my-payments-alerts">
    <div class="page-subtitle" v-if="onboardingWarning" style="margin-top: 2em">
      Please check your
      <router-link to="/my-agreements">My Agreements</router-link> page to
      ensure you have completed all standard onboarding paperwork (MSA, W-9, ACH
      Authorization) and then return to this page to submit invoices.
      <br />
    </div>
    <div
      class="page-subtitle"
      v-else-if="currentMSAWarning"
      style="margin-top: 2em"
    >
      You have not completed an MSA for the current year. Please check your
      <router-link to="/my-agreements">My Agreements</router-link> page and sign
      the MSA before submitting an invoice for payment.
      <br />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    onboardingWarning() {
      if (!this.currentUser) return false;
      const onbDetails = this.currentUser.onboarding_details;
      return (
        !onbDetails.has_any_signed_msa ||
        onbDetails.w9_status != "SIGNED" ||
        onbDetails.ach_status != "SIGNED"
      );
    },
    currentMSAWarning() {
      if (!this.currentUser) return false;
      const onbDetails = this.currentUser.onboarding_details;
      return onbDetails.has_any_signed_msa && onbDetails.msa_status != "SIGNED";
    },
  },
};
</script>
