<template>
  <div class="review-group">
    <div class="review-group__top">
      <div class="review-group__title">
        {{ value.title }}
      </div>
      <div class="review-group__status">
        <SubmissionFormStage :submissionForm="value" :value="value.state" />
      </div>
    </div>
    <div
      v-if="value.description"
      class="review-group__description"
      v-html="value.description"
    ></div>
    <div class="review-group__dates">
      <div class="review-group__date" v-if="value.opening_date">
        <span style="color: #13b219">OPENING</span>
        <span>
          {{
            value.opening_date
              | moment("timezone", currentTimezone, "MMM D, YYYY h:mm A zz")
          }}
        </span>
      </div>
      <div class="review-group__date" v-if="value.closing_date">
        <span style="color: #e12d1b">CLOSING</span>
        <span>{{
          value.closing_date
            | moment("timezone", currentTimezone, "MMM D, YYYY h:mm A zz")
        }}</span>
      </div>
    </div>
    <div class="review-group__eligibilities">
      <b>ELIGIBLE</b>
      <Eligibles
        v-if="value.eligibilities.length"
        :value="value.eligibilities"
      />
      <span v-else>All Community Creators</span>
    </div>
    <div class="review-group__eligibilities-mobile">
      <b>ELIGIBLE</b>
      <Eligibles
        v-if="value.eligibilities.length"
        :value="value.eligibilities"
      />
      <span v-else>All Community Creators</span>
    </div>
    <div class="review-group__links">
      <v-btn :to="`/review/${value.id}`" color="primary" elevation="0"
        >Review Submissions</v-btn
      >
      <v-btn
        v-if="currentUser.is_judging_coordinator"
        :to="`/judging/${value.id}`"
        color="primary"
        elevation="0"
        >All Reviews</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.review-group {
  padding: 40px;
  background: white;
  border: 2px solid #262729;
  margin-top: -2px;
  @media screen and (max-width: 960px) {
    padding: 25px 12px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid black;
    padding-bottom: 22px;
    margin-bottom: 19px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 1em;
  }

  &__dates {
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__date {
    font-weight: bold;
    margin-right: 30px;

    span {
      padding: 0 3px 0 0;
    }
  }

  &__eligibilities {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 960px) {
      display: none;
    }

    b {
      margin-right: 7px;
      color: #56565a;
    }
  }
  &__eligibilities-mobile {
    display: none;

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 17px;
      margin-right: -20px;
      font-size: 14px;
      > b {
        margin-bottom: 11px;
      }
      .eligibles {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__links {
    margin-top: 15px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    color: #262729;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__status {
    flex-basis: 260px;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 960px) {
  .review-group__eligibilities-mobile .eligible {
    margin-bottom: 18px;
  }
}
</style>
