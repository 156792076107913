<template>
  <tr class="payments-row judging-row" v-if="item">
    <td class="td--creator">
      <div @click="$emit('openDialog')">{{ item.user.display_name }}</div>
    </td>
    <SubmissionsReviewTableCell
      v-for="field in judgingColumns"
      :key="field.uuid"
      :field="field"
      :response="responseFromField(field)"
    />
    <td class="center-align" v-for="reviewer in reviewers" :key="reviewer.id">
      <div v-if="hasReview(item, reviewer)" class="rating-points">
        {{ item.ratings[reviewer.id].rating }}
      </div>
      <span v-else class="payments-row__pending">—</span>
    </td>
    <td class="td--judging-total">
      <div
        v-if="item.ratings && Object.keys(item.ratings).length"
        class="rating-points"
      >
        {{ item.avg_rating }}
      </div>
      <div v-else class="payments-row__pending">—</div>
    </td>
  </tr>
</template>

<script>
import SubmissionsReviewTableCell from "../review/shared/SubmissionsReviewTableCell";
export default {
  components: { SubmissionsReviewTableCell },
  props: {
    item: Object,
    reviewers: Array,
    submissionForm: Object,
  },
  methods: {
    hasReview(item, reviewer) {
      return item.ratings && item.ratings[reviewer.id];
    },
    responseFromField(field) {
      return this.item.formatted_data.find(
        (itemField) => itemField.uuid == field.uuid
      );
    },
  },
  computed: {
    judgingColumns() {
      return this.submissionForm.submission_form.filter(
        (field) => field.judging_column
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.judging-row {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  gap: 2px;

  td {
    border: none !important;
    outline: 1px solid #262729;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      /* preserve right border when sticky */
      &::before {
        position: absolute;
        content: "";
        display: block;
        background: #262729;
        width: 2px;
        top: 0;
        bottom: 0;
        right: -2px;
      }
    }
    &:last-child {
      position: sticky;
      right: 0;
      z-index: 1;

      /* preserve left border when sticky */
      &::before {
        position: absolute;
        content: "";
        display: block;
        background: #262729;
        width: 2px;
        top: 0;
        bottom: 0;
        left: -2px;
      }
    }
  }
}

.rating-points {
  font-weight: bold;
}

.td--creator {
  text-align: center;
  color: #e12d1b;
  padding: 0 !important;
  align-items: flex-start;
  background: transparent;
  > div:hover {
    cursor: pointer;
  }
  > div {
    background: white;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* fixes for subgrid/outline glitch */
    width: 100%;
    height: calc(100% - 2px);
  }
}

.td--judging-total {
  text-align: center;
  color: white;
  padding: 0 !important;
  align-items: flex-start;
  > div {
    background: #4282ff;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* fixes for subgrid/outline glitch */
    width: 100%;
    height: calc(100% - 1px);
  }
}

/* fixes for subgrid/outline glitch */
.judging-row:first-child .td--judging-total > div {
  height: 100%;
}
.judging-row:not(:first-child) .td--judging-total > div {
  margin-top: 1px;
}
</style>
