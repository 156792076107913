<template>
  <div class="judging-ratings">
    <div class="judging-ratings__title">Reviews</div>
    <div class="judging-ratings__swiper-wrapper">
      <div
        class="
          judging-ratings__swiper-control judging-ratings__swiper-button-prev
        "
        @click="prev()"
        v-if="list && list.length > 1"
      >
        <v-icon color="#E12D1B" size="50">$arrow_left_alt</v-icon>
      </div>
      <div
        class="
          judging-ratings__swiper-control judging-ratings__swiper-button-next
        "
        @click="next()"
        v-if="list && list.length > 1"
      >
        <v-icon color="#E12D1B" size="50">$arrow_right_alt</v-icon>
      </div>
      <swiper
        ref="swiper"
        class="judging-ratings__swiper"
        :options="swiperOption"
        v-if="swiperOption"
      >
        <swiper-slide
          class="judging-ratingsg__swiper-slide"
          v-for="item in list"
          :key="`rating--${item.id}`"
        >
          <div class="judging-ratings__ratings">
            <div
              v-for="rating in ratingsData"
              :key="`rating--${item.id}--${rating.uuid}`"
              class="judging-ratings__rating"
            >
              <div class="judging-ratings__rating__label">
                {{ rating.title }}
              </div>
              <div class="judging-ratings__rating__value">
                {{ item.data[rating.uuid] }}
              </div>
            </div>
            <div
              :key="`rating--${item.id}--total`"
              class="judging-ratings__rating"
            >
              <div class="judging-ratings__rating__label">Total</div>
              <div class="judging-ratings__rating__value">
                {{ item.rating }}
              </div>
            </div>
          </div>
          <div class="judging-ratings__user-notes">
            <div class="judging-ratings__user">
              Reviewed by {{ item.user.display_name }} on
              {{ dateFormatted(item.created_at, "MMMM Do YYYY, h:mma zz") }}
            </div>
            <div class="judging-ratings__notes" v-if="item.notes">
              {{ item.notes }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  props: {
    list: Array,
    ratingsData: Array,
  },
  data() {
    return {
      swiperOption: {
        keyboard: {
          enabled: true,
        },
        loop: true,
        navigation: {
          nextEl: ".judging-ratings__swiper-button-next",
          prevEl: ".judging-ratings__swiper-button-prev",
        },
        enabled: this.list.length > 1,
      },
    };
  },
  methods: {
    prev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    next() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
  },
};
</script>

<style scoped lang="scss">
.judging-ratings {
  border-top: 2px solid #262729;
  padding: 35px 50px 35px 35px;

  &__title {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 25px;
  }

  &__ratings {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-bottom: 30px;
  }

  &__rating {
    &__value {
      font-weight: bold;
    }
  }

  &__user-notes {
    font-size: 14px;
    line-height: 1.286;
  }

  &__user {
    color: #e12d1b;
    font-weight: 700;
  }

  &__notes {
    font-weight: 300;
    white-space: pre-wrap;
  }
  &__swiper-wrapper {
    position: relative;
  }
  &__swiper-control {
    position: absolute;
    top: calc(50% - 50px);
    cursor: pointer;
  }
  &__swiper-button-prev {
    left: -40px;
  }
  &__swiper-button-next {
    right: -40px;
  }
}
</style>
