<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :items="list"
      outlined
      min-height="50"
      class="wa-selector"
      item-text="label"
      item-value="id"
      clearable
      hide-details="auto"
      :rules="rules"
      dense
      elevation="0"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content
            class="wa-selector__item-content"
            :class="{ 'wa-selector__item-content--na': !data.item.id }"
          >
            <div>
              {{ data.item.label }}
              <v-tooltip
                right
                v-if="
                  data.item.id &&
                  (data.item.agreement_status != 'SIGNED' ||
                    data.item.msa_status != 'SIGNED')
                "
                @click.stop
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="22"
                    color="#e12d1b"
                    class="wa-selector__item-content--alert"
                    >$error</v-icon
                  >
                </template>
                <div>
                  <div v-if="data.item.agreement_status != 'SIGNED'">
                    A signed
                    {{
                      data.item.agreement_status ==
                      "EXTENSION_OUT_FOR_SIGNATURE"
                        ? "contract extension"
                        : "contract"
                    }}
                    is needed before submitting invoices for this gig.
                  </div>
                  <div v-if="data.item.msa_status != 'SIGNED'">
                    A signed MSA is needed for year
                    {{ getYear(data.item) }} before submitting invoices for this
                    gig.
                  </div>
                </div>
              </v-tooltip>
            </div>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    list: Array,
    rules: Array,
  },
  methods: {
    getYear(item) {
      return new Date(item.start_date).getUTCFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.wa-selector {
  width: 100%;

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    &.hidden-input input {
      display: none;
    }
    &.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 40px;
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 50px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
      margin-top: 12px !important;
    }

    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }

    label {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.38);
    }
    &.v-text-field--outlined.v-input--dense {
      .v-label {
        top: 14px;
      }
      .v-label--active {
        transform: translateY(-21px) scale(0.75);
      }
    }
  }
}
</style>

<style lang="scss">
.v-autocomplete__content
  .v-list-item--disabled
  .wa-selector__item-content:not(.wa-selector__item-content--na) {
  font-style: italic;
  color: gray;
}
.v-autocomplete__content .v-list-item--disabled .v-ripple__container {
  display: none !important;
}
.wa-selector__item-content--alert {
  pointer-events: all;
}
</style>
