<template>
  <div class="review-table-wrapper">
    <div class="review-table-anchor" ref="anchor"></div>
    <div class="review-table-container" :key="`submissions-table-${reloadKey}`">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="center-align">Name</th>
            <th v-for="item in submissionForm.ratings_data" :key="item.uuid">
              {{ item.title }}
            </th>
            <th class="w0 center-align">
              <div>
                {{
                  submissionForm.rating_type == "multi_category_points"
                    ? "Total Rating"
                    : "Weighted Avg."
                }}
              </div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="rating"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Rating"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <MultiCategorySubmissionsReviewTableRow
            v-for="(item, idx) in items"
            :key="item.id"
            :item="item"
            @openDialog="openDialog(idx)"
            :submissionForm="submissionForm"
          />
        </tbody>
        <tbody class="no-entries" v-else-if="items && items.length == 0">
          <tr>
            <td colspan="1000">No Submissions</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
    <v-dialog v-model="dialog" content-class="submission-review-dialog">
      <v-card light class="submission-review-dialog-container">
        <v-btn class="dialog-close" icon @click="cancel">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text
          class="submission-review-dialog__content"
          v-if="activeSubmissionIdx !== null && items[activeSubmissionIdx]"
        >
          <SubmissionReviewForm
            class="submission-review-dialog__form"
            :submission="items[activeSubmissionIdx]"
            :submissionForm="submissionForm"
            @change="reviewHasUnsavedChanges = $event"
            :key="`submission-review-form__${activeSubmissionIdx}`"
            @prev="prev($event)"
            @next="next($event)"
            @refresh="
              $emit('refresh');
              dialog = false;
            "
            :submissionIndex="activeSubmissionIdx"
            :submissionsLength="items.length"
            @orderInvalid="clearOrder"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableHeaderActions from "@/components/tables/TableHeaderActions";
import MultiCategorySubmissionsReviewTableRow from "./MultiCategorySubmissionsReviewTableRow.vue";
import SubmissionReviewForm from "@/components/review/SubmissionReviewForm.vue";
import submissionsReviewGroup from "@/mixins/submissions/submissions-review-group.mixin";

export default {
  mixins: [submissionsReviewGroup],
  components: {
    TableHeaderActions,
    MultiCategorySubmissionsReviewTableRow,
    SubmissionReviewForm,
  },
  props: {
    submissionForm: Object,
    items: Array,
    listOptions: Object,
    reloadKey: Number,
  },
  data() {
    return {
      dialog: false,
      activeSubmissionIdx: null,
      reviewHasUnsavedChanges: false,
    };
  },
  mounted() {
    if (this.$route.query.submission_id) {
      this.openFromQueryParam(this.$route.query.submission_id);
    }
  },
  watch: {
    "$route.query.submission_id": {
      handler: function (sid) {
        if (sid !== null && sid !== this.activeSubmissionIdx) {
          this.openFromQueryParam(sid);
        }
      },
      deep: true,
    },
  },
  computed: {
    previewFields() {
      return this.submissionForm.submission_form.filter(
        (field) => field.review_preview
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
@import "@/styles/review/review-table.scss";
</style>
