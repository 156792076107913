<template>
  <div
    class="review-form"
    :class="{ 'review-form--gig': submissionForm.project_type == 'gig' }"
  >
    <div class="review-form__header">
      <div class="review-form__title">
        {{ submissionForm.title }}
      </div>
      <div class="review-form__nav">
        <div class="review-form__nav__position-indicator">
          Submission {{ submissionIndex + 1 }} of
          {{ submissionsLength }}
        </div>
        <div class="review-form__nav__btns">
          <div class="review-form__nav__btn" @click="$emit('prev')">
            <v-icon
              :color="
                submissionForm.project_type == 'gig' ? 'white' : '#e12d1b'
              "
              >$chevron_left_filled</v-icon
            >
          </div>
          <div class="review-form__nav__btn" @click="$emit('next')">
            <v-icon
              :color="
                submissionForm.project_type == 'gig' ? 'white' : '#e12d1b'
              "
              >$chevron_right_filled</v-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="review-form__content">
      <SubmissionReviewItem
        class="review-form__submission"
        :item="submission"
        :submissionForm="submissionForm"
        :key="`review-form-item__${submission.id}`"
      />
      <JudgingRatings
        v-if="list"
        :list="list"
        :ratingsData="submissionForm.ratings_data"
      />
    </div>
  </div>
</template>

<script>
import SubmissionReviewItem from "@/components/review/SubmissionReviewItem.vue";
import JudgingRatings from "./ratings.judging";

export default {
  components: {
    SubmissionReviewItem,
    JudgingRatings,
  },
  props: {
    submission: Object,
    submissionForm: Object,
    submissionIndex: Number,
    submissionsLength: Number,
  },
  data() {
    return {
      list: null,
    };
  },
  mounted() {
    this.fetchReviews();
  },
  methods: {
    async fetchReviews() {
      const { data } = await this.$api.SubmissionRating.get({
        submission_id: this.submission.id,
      });
      this.list = data;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/review/review-dialog.scss";
</style>
