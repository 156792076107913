<template>
  <div class="inc-form-dialog">
    <v-overlay :value="dialog" @click.native.stop="$emit('close')" />
    <v-menu
      content-class="hint-menu__menu"
      bottom
      :offset-y="false"
      :close-on-click="false"
      :close-on-content-click="false"
      v-model="dialog"
      light
    >
      <template v-slot:activator>
        <div></div>
      </template>
      <v-card class="hint-menu__menu__content" width="200">
        <v-btn class="dialog-close" icon @click="$emit('close')" color="black">
          <v-icon size="20">$close</v-icon>
        </v-btn>
        <div class="hint-menu__menu__main">
          <div>
            You missed a category! Close this dialogue to complete the review
            now or use the button below to advance to the next submission.
          </div>
          <v-btn
            color="primary"
            @click="$emit('advanceAnyway')"
            elevation="0"
            x-large
            >Advance Anyway</v-btn
          >
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/hint-menu.scss";
.inc-form-dialog {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
