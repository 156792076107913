<template>
  <tr class="payments-row" v-if="item">
    <td :class="{ 'review-needed': !item.my_rating }">
      {{ item.user.display_name }}
    </td>
    <td class="w0 center-align">
      {{ item.created_at ? dateFormatted(item.created_at, "MM/DD/YYYY") : "" }}
    </td>
    <td style="text-align: right">{{ budget }}</td>
    <td class="center-align">
      <div v-if="item.my_rating" class="rating-points">
        {{ item.my_rating.rating }}
      </div>
      <span v-else class="payments-row__pending">Pending</span>
    </td>
    <td>
      <div class="td-action">
        <v-btn icon @click="$emit('openDialog')">
          <v-icon size="35">$arrow_right</v-icon>
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  props: {
    item: Object,
  },
  methods: {
    findByShortName(shortName) {
      return this.item.formatted_data.find(
        (field) =>
          field.short_name &&
          field.short_name.toLowerCase() == shortName.toLowerCase()
      );
    },
  },
  computed: {
    budget() {
      if (!this.item) return "";
      const field = this.findByShortName("budget");
      return field ? field.value : "N/A";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.review-needed {
  font-weight: bold;
  color: var(--v-primary-base);
}
.rating-points {
  font-weight: bold;
}
</style>
