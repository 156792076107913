<template>
  <div class="login-page">
    <AuthRegisterForm />
  </div>
</template>

<script>
export default {
  head: {
    title: "Login",
    titleTemplate: "%s | iU Community",
  },
};
</script>

<style scoped lang="scss">
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
