<template>
  <tr class="payments-row" v-if="item">
    <td class="td--creator">
      <span @click="$emit('openDialog')">{{ item.user.display_name }}</span>
    </td>
    <td
      class="center-align w20"
      v-for="(rating, idx) in submissionForm.ratings_data"
      :key="`td--${idx}`"
    >
      <div
        v-if="
          item.my_rating &&
          typeof item.my_rating.data[rating.uuid] !== 'undefined'
        "
        class="rating-points"
      >
        {{ item.my_rating.data[rating.uuid] }}
      </div>
      <span v-else class="payments-row__pending">Not Reviewed</span>
    </td>
    <td class="center-align w20">
      <div v-if="item.my_rating" class="rating-points">
        {{ item.my_rating.rating }}
      </div>
      <span v-else class="payments-row__pending">Not Reviewed</span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: Object,
    submissionForm: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.rating-points {
  font-weight: bold;
}

.td--creator {
  text-align: center;
  color: #e12d1b;
  > span:hover {
    cursor: pointer;
  }
}
</style>
