<template>
  <div class="review-page">
    <router-link class="back-btn" to="/for-review">
      <v-icon size="50">$arrow_left</v-icon>
    </router-link>
    <div v-if="submissionForm && list">
      <div class="review-page__header">
        <div class="page-title">
          My Reviews<br />
          <a :href="this.submissionForm.opp_url"
            >{{
              submissionForm.project_code || submissionForm.title
            }}
            Submissions</a
          >
        </div>
        <div>
          <form
            method="post"
            action="/api/v1/profile_side/submission_forms/review_submissions_csv"
            target="_blank"
          >
            <input type="hidden" name="id" :value="submissionForm.id" />
            <input
              type="hidden"
              name="token"
              :value="$store.getters['auth/token']"
            />
            <input type="hidden" name="all" value="true" />
            <input
              type="hidden"
              v-for="item in Object.entries(listParams)"
              :key="`listParams-${item[0]}`"
              :name="item[0]"
              :value="item[1]"
            />
            <v-btn
              plain
              type="submit"
              elevation="0"
              class="review-page__csv-btn"
              >Download as CSV</v-btn
            >
          </form>
        </div>
      </div>
      <component
        :is="
          submissionForm.project_type == 'gig'
            ? 'GigSubmissionsReviewTable'
            : submissionForm.rating_type == 'simple'
            ? 'SubmissionsReviewTable'
            : 'MultiCategorySubmissionsReviewTable'
        "
        :submissionForm="submissionForm"
        :items="list"
        :listOptions="listOptions"
        @orderUpdate="orderUpdate($event)"
        :reloadKey="reloadKey"
        @refresh="refresh()"
        :class="{ 'table-loading': loadingList }"
      />
      <div class="review-page__bottom-actions">
        <v-tooltip top :disabled="submitScoresheetAvailable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              color="primary"
              elevation="0"
              class="review-page__submit-scoresheet-btn"
              :class="{
                'review-page__submit-scoresheet-btn--disabled':
                  !submitScoresheetAvailable,
              }"
              @click="openSubmitScoresheetDialog"
              v-bind="attrs"
              v-on="on"
              >Submit Your Scoresheet</v-btn
            >
          </template>
          <span v-if="this.submissionForm.state == 'submitted'"
            >You cannot submit your scoresheet until after submissions have
            closed.</span
          >
          <span v-if="this.submissionForm.my_review_status == 'completed'"
            >You have already submitted your scoresheet.</span
          >
          <span v-if="!allSubmissionsReviewed"
            >Some submissions have not been rated.</span
          >
        </v-tooltip>
        <router-link class="back-btn--bottom" to="/for-review">
          <div><v-icon size="50" color="#4282FF">$arrow_left</v-icon></div>
          <div>Back to For Review</div>
        </router-link>
      </div>
      <AreYouSureDialog
        :dialog="submitScoresheetDialog"
        @yes="submitScoresheet"
        @no="submitScoresheetDialog = false"
      >
        <p>Are you sure?</p>
        <p style="font-weight: 400">
          Once you submit your scoresheet, you won’t be able to make any further
          changes to your submission ratings.
        </p>
      </AreYouSureDialog>
    </div>
  </div>
</template>

<script>
import SubmissionsReviewTable from "./shared/SubmissionsReviewTable";
import MultiCategorySubmissionsReviewTable from "./shared/MultiCategorySubmissionsReviewTable";
import GigSubmissionsReviewTable from "./shared/GigSubmissionsReviewTable";
import submissionsReviewTableActions from "@/mixins/submissions/submissions-review-table-actions.mixin";
import AreYouSureDialog from "@/components/AreYouSureDialog.vue";

export default {
  components: {
    SubmissionsReviewTable,
    MultiCategorySubmissionsReviewTable,
    GigSubmissionsReviewTable,
    AreYouSureDialog,
  },
  mixins: [submissionsReviewTableActions],
  head: {
    title: "Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      submissionForm: null,
      list: null,
      loadingList: false,
      submitScoresheetDialog: false,
    };
  },
  mounted() {
    this.fetchForm();
    this.fetchList();
  },
  methods: {
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.review({
        id: this.$route.params.id,
      });
      this.submissionForm = data;
    },
    async fetchList() {
      this.loadingList = true;
      const params = { ...this.listParams };
      params["id"] = this.$route.params.id;
      const { data } = await this.$api.SubmissionForm.reviewSubmissions(params);
      this.list = data.submissions;
      this.loadingList = false;
    },
    async refresh() {
      this.fetchForm();
      this.fetchList();
    },
    openSubmitScoresheetDialog() {
      if (this.submissionForm.state == "submitted") return;
      if (this.submissionForm.my_review_status == "completed") return;
      if (!this.allSubmissionsReviewed) return;
      this.submitScoresheetDialog = true;
    },
    async submitScoresheet() {
      try {
        const { status } = await this.$api.SubmissionForm.submitScoresheet({
          submission_form_id: this.submissionForm.id,
        });
        if (status == 200) {
          this.$alert("Your Scoresheet has been submitted.");
          this.refresh();
        }
      } catch (e) {
        this.pleaseWait = false;
        this.$alert(e.response.data.msg, "error");
      }
      this.submitScoresheetDialog = false;
    },
  },
  computed: {
    allSubmissionsReviewed() {
      return (
        this.list &&
        this.list.length &&
        this.list.filter((item) => !item.my_rating).length == 0
      );
    },
    submitScoresheetAvailable() {
      return (
        this.submissionForm.state == "review" &&
        this.submissionForm.my_review_status != "completed" &&
        this.allSubmissionsReviewed
      );
    },
  },
};
</script>

<style scoped lang="scss">
.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
.review-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
  }
  .page-title {
    margin-bottom: 15px;
  }

  &__csv-btn,
  &__submit-scoresheet-btn {
    &--disabled {
      opacity: 0.5;
      cursor: default;
    }

    &::before,
    &::v-deep .v-ripple__container {
      display: none !important;
    }
  }

  &__submit-scoresheet-btn {
    font-size: 14px;
    width: 249px;
    max-width: 100%;
  }

  &__csv-btn {
    background-color: transparent !important;
    color: #262729 !important;
    font-weight: 400 !important;
    font-size: 20px;
    padding: 0 !important;
  }

  &__csv-btn::after {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikdyb3VwIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTE3LjEwNjcgOS4wNjU4QzE5LjM5MDQgOS4wNzg0IDIwLjYyNzMgOS4xODAyNSAyMS40MzM3IDkuOTg2NjVDMjIuMzU2NyAxMC45MDk2IDIyLjM1NjcgMTIuMzk0MyAyMi4zNTY3IDE1LjM2MzdWMTYuNDEzN0MyMi4zNTY3IDE5LjM4NDEgMjIuMzU2NyAyMC44Njg4IDIxLjQzMzcgMjEuNzkxOEMyMC41MTE4IDIyLjcxMzcgMTkuMDI2MSAyMi43MTM3IDE2LjA1NjcgMjIuNzEzN0g3LjY1NjY5QzQuNjg3MjkgMjIuNzEzNyAzLjIwMTU0IDIyLjcxMzcgMi4yNzk2NCAyMS43OTE4QzEuMzU2NjkgMjAuODY3OCAxLjM1NjY5IDE5LjM4NDEgMS4zNTY2OSAxNi40MTM3VjE1LjM2MzdDMS4zNTY2OSAxMi4zOTQzIDEuMzU2NjkgMTAuOTA5NiAyLjI3OTY0IDkuOTg2NjVDMy4wODYwNCA5LjE4MDI1IDQuMzIyOTQgOS4wNzg0IDYuNjA2NjkgOS4wNjU4IiBzdHJva2U9IiMyNjI3MjkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggaWQ9IlZlY3Rvcl8yIiBkPSJNMTEuODU2OCAxLjcxMzc1VjE1LjM2MzdNMTEuODU2OCAxNS4zNjM3TDguNzA2NzkgMTEuNjg4N00xMS44NTY4IDE1LjM2MzdMMTUuMDA2OCAxMS42ODg3IiBzdHJva2U9IiMyNjI3MjkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K");
    margin-left: 13px;
  }

  &__bottom-actions {
    margin-top: 37px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    font-size: 14px;

    .back-btn--bottom {
      display: block;
      text-decoration: none;
      color: #262729;
      display: flex;
      align-items: center;
      @media screen and (max-width: 960px) {
        margin-left: -10px;
      }
    }
  }
}
</style>
