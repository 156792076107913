<template>
  <div>
    <WorkAssignmentSelector
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :list="listComputed"
      :rules="rules"
    />
  </div>
</template>

<script>
import WorkAssignmentSelector from "@/components/selects/WorkAssignmentSelector";

export default {
  components: { WorkAssignmentSelector },
  props: {
    value: [String, Number, Array],
    rules: Array,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.setWorkAssignments();
  },
  methods: {
    async setWorkAssignments() {
      const { data } = await this.$api.WorkAssignment.myLite();
      this.list = [{ label: "N/A", id: null }, ...data.map((t) => ({ ...t }))];
    },
  },
  computed: {
    listComputed() {
      return this.list.map((item) => {
        item.disabled =
          item.agreement_status != "SIGNED" || item.msa_status != "SIGNED";
        return item;
      });
    },
  },
};
</script>
