<template>
  <div class="judging-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div v-if="submissionForm && list">
      <div class="judging-page__header">
        <div class="page-title">
          All Reviews -
          {{ submissionForm.project_code || submissionForm.title }}
          Submissions
        </div>
        <div class="judging-page__scroll-x-btns" v-if="xScroll.enabled">
          <div>
            <v-icon
              class="judging-page__scroll-x-btn"
              size="50"
              color="#E8852B"
              @click="scrollLeft()"
              >$arrow_left</v-icon
            >
          </div>
          <div>
            <v-icon
              class="judging-page__scroll-x-btn"
              size="50"
              color="#E8852B"
              @click="scrollRight()"
              >$arrow_right</v-icon
            >
          </div>
        </div>
      </div>
      <JudgingTable
        :items="list"
        :reviewers="reviewers"
        :submissionForm="submissionForm"
        :listOptions="listOptions"
        @orderUpdate="orderUpdate($event)"
        :class="{ 'table-loading': loadingList }"
        ref="judgingTable"
        v-resize="updateXScrolling"
        @refresh="refresh()"
      />
    </div>
    <router-link class="back-btn--bottom" to="/for-review">
      <div><v-icon size="50" color="#4282FF">$arrow_left</v-icon></div>
      <div>Back to For Review</div>
    </router-link>
  </div>
</template>

<script>
import JudgingTable from "./table.judging";
import judgingTableActions from "@/mixins/submissions/judging-table-actions.mixin";

export default {
  head: {
    title: "Judging",
    titleTemplate: "%s | iU Community",
  },
  components: {
    JudgingTable,
  },
  mixins: [judgingTableActions],
  data() {
    return {
      submissionForm: null,
      list: null,
      reviewers: null,
      loadingList: false,
      xScroll: {
        enabled: false,
      },
    };
  },
  mounted() {
    this.fetchForm();
    this.fetchList();
  },
  methods: {
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.review({
        id: this.$route.params.id,
      });
      this.submissionForm = data;
    },
    async fetchList() {
      this.loadingList = true;
      const params = { ...this.listParams };
      params["id"] = this.$route.params.id;
      const { data } = await this.$api.SubmissionForm.judging(params);
      this.list = data.submissions;
      this.reviewers = data.reviewers;
      this.loadingList = false;
    },
    async refresh() {
      this.fetchForm();
      this.fetchList();
    },
    updateXScrolling() {
      const wTable = this.$refs.judgingTable.$refs.table.offsetWidth;
      const wTheadTr = this.$refs.judgingTable.$refs.theadTr.offsetWidth;
      this.xScroll.enabled = wTheadTr > wTable;
    },
    scrollLeft() {
      const xTable = this.$refs.judgingTable.$refs.table.offsetLeft;
      const xScrollTable = this.$refs.judgingTable.$refs.table.scrollLeft;
      const columns = [...this.$refs.judgingTable.$refs.theadTr.children];
      const xRightFirst =
        columns[0].offsetLeft + columns[0].offsetWidth - xTable;
      const xLeftColumns = columns
        .map((th) => th.offsetLeft - xTable)
        .slice(1, -1); // ignore first and last columns
      const xNext = xLeftColumns.reverse().find((x) => x < xRightFirst);
      if (xNext) {
        this.$refs.judgingTable.$refs.table.scrollTo({
          left: xScrollTable - (xRightFirst - xNext),
          behavior: "smooth",
        });
      } else {
        this.$refs.judgingTable.$refs.table.scrollTo({
          left: this.$refs.judgingTable.$refs.theadTr.offsetWidth,
          behavior: "smooth",
        });
      }
    },
    scrollRight() {
      const xTable = this.$refs.judgingTable.$refs.table.offsetLeft;
      const xScrollTable = this.$refs.judgingTable.$refs.table.scrollLeft;
      const columns = [...this.$refs.judgingTable.$refs.theadTr.children];
      const xRightColumns = columns
        .map((th) => th.offsetLeft + th.offsetWidth - xTable)
        .slice(1, -1); // ignore first and last columns;
      const xLast = columns.slice(-1)[0].offsetLeft - xTable;
      const xNext = xRightColumns.find((x) => x > xLast);
      if (xNext) {
        this.$refs.judgingTable.$refs.table.scrollTo({
          left: xScrollTable + xNext - xLast,
          behavior: "smooth",
        });
      } else {
        this.$refs.judgingTable.$refs.table.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
.judging-page {
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  &__scroll-x-btns {
    display: flex;
    margin-left: auto;
  }
  &__scroll-x-btn {
    &::before,
    &::after,
    &::v-deep .v-ripple__container {
      display: none !important;
    }
  }
}
.back-btn--bottom {
  margin-top: 60px;
  display: block;
  text-decoration: none;
  color: #262729;
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    margin-left: -10px;
  }
}
</style>
