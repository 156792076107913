<template>
  <div class="judging-table-wrapper">
    <div class="judging-table-container">
      <table
        border="2"
        class="payments-table judging-table"
        :style="tableStyles"
        ref="table"
      >
        <thead class="payments-table-head">
          <tr ref="theadTr">
            <th class="center-align">Submitter</th>
            <th v-for="item in judgingColumns" :key="item.uuid">
              {{ item.short_label || item.label }}
            </th>
            <th v-for="reviewer in reviewers" :key="reviewer.uuid">
              <ReviewerActionsMenu
                :reviewer="reviewer"
                :submissionForm="submissionForm"
                @refresh="$emit('refresh')"
              />
            </th>
            <th class="center-align">
              <div>Average</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="avg_rating"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Rating"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <JudgingTableRow
            v-for="(item, idx) in items"
            :key="item.id"
            :item="item"
            :reviewers="reviewers"
            @openDialog="openDialog(idx)"
            :submissionForm="submissionForm"
          />
        </tbody>
      </table>
    </div>
    <v-dialog v-model="dialog" content-class="submission-review-dialog">
      <v-card light class="submission-review-dialog-container">
        <v-btn class="dialog-close" icon @click="cancel">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text
          class="submission-review-dialog__content"
          v-if="activeSubmissionIdx !== null && items[activeSubmissionIdx]"
        >
          <SubmissionJudging
            class="submission-review-dialog__form"
            :submission="items[activeSubmissionIdx]"
            :submissionForm="submissionForm"
            :key="`submission-review-form__${activeSubmissionIdx}`"
            @prev="prev()"
            @next="next()"
            :submissionIndex="activeSubmissionIdx"
            :submissionsLength="items.length"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableHeaderActions from "@/components/tables/TableHeaderActions";
import JudgingTableRow from "./table-row.judging";
import SubmissionJudging from "./submission.judging";
import ReviewerActionsMenu from "./ReviewerActionsMenu";

export default {
  components: {
    TableHeaderActions,
    JudgingTableRow,
    SubmissionJudging,
    ReviewerActionsMenu,
  },
  props: {
    submissionForm: Object,
    items: Array,
    reviewers: Array,
    listOptions: Object,
  },
  data() {
    return {
      dialog: false,
      activeSubmissionIdx: null,
    };
  },
  mounted() {
    this.$nextTick(() => this.$emit("updateXScrolling"));
  },
  methods: {
    openDialog(idx) {
      this.activeSubmissionIdx = idx;
      this.dialog = true;
    },
    prev() {
      this.activeSubmissionIdx =
        (this.activeSubmissionIdx + this.items.length - 1) % this.items.length;
    },
    next() {
      this.activeSubmissionIdx =
        (this.activeSubmissionIdx + 1) % this.items.length;
    },
    cancel() {
      this.dialog = false;
      this.activeSubmissionIdx = 0;
    },
  },
  computed: {
    judgingColumns() {
      return this.submissionForm.submission_form.filter(
        (field) => field.judging_column
      );
    },
    tableStyles() {
      const judgingColumnsCount = this.judgingColumns
        ? this.judgingColumns.length
        : 0;
      const reviewerCount = this.reviewers ? this.reviewers.length : 0;
      return {
        "grid-template-columns": `3fr ${"2fr ".repeat(
          judgingColumnsCount + reviewerCount
        )} 3fr`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
@import "@/styles/review/review-table.scss";

.judging-table {
  display: grid;
  border: none !important;
  outline: 2px solid #262729;
  gap: 2px;
  overflow-x: auto;

  thead,
  tbody,
  tr {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }

  th {
    border: none !important;
    outline: 1px solid #262729;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #7b61ff;
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      /* preserve right border when sticky */
      &::before {
        position: absolute;
        content: "";
        display: block;
        background: #262729;
        width: 2px;
        top: 0;
        bottom: 0;
        right: -2px;
      }
    }
    &:last-child {
      position: sticky;
      right: 0;
      z-index: 1;

      /* preserve left border when sticky */
      &::before {
        position: absolute;
        content: "";
        display: block;
        background: #262729;
        width: 2px;
        top: 0;
        bottom: 0;
        left: -2px;
      }
    }
  }
}
</style>
