import sharedTableActions from "@/mixins/shared-table-actions.mixin";

export default {
  mixins: [sharedTableActions],
  data() {
    return {
      dateParams: [],
      listOptions: this.getDefaultListOptions(),
    };
  },
  methods: {
    getDefaultListOptions() {
      return {
        order_by: "avg_rating",
        order_direction: "DESC",
        page: 1,
      };
    },
    resetListOptions() {
      this.listOptions = this.getDefaultListOptions();
      this.searchInput = null;
      this.fetchList();
      this.reloadKey++;
    },
    resetFilter(fieldName) {
      this.listOptions[fieldName] = null;
      this.optionsUpdate();
    },
    activeListOption(fieldName) {
      return this.listOptions[fieldName];
    },
  },
  computed: {
    listParams() {
      return {
        order_by: this.listOptions.order_by,
        order_direction: this.listOptions.order_direction,
      };
    },
    anyActiveListOptions() {
      return (
        this.listOptions.order_by != "avg_rating" ||
        this.listOptions.order_direction != "DESC"
      );
    },
  },
};
