export default {
  props: {
    submissionForm: Object,
    items: Array,
    listOptions: Object,
    reloadKey: Number,
  },
  data() {
    return {
      dialog: false,
      activeSubmissionIdx: null,
      reviewHasUnsavedChanges: false,
    };
  },
  mounted() {
    if (this.$route.query.submission_id) {
      this.openFromQueryParam(this.$route.query.submission_id);
    }
  },
  methods: {
    openFromQueryParam(submissionId) {
      const idx = this.items.findIndex((item) => item.id == submissionId);
      if (idx != -1) {
        this.activeSubmissionIdx = idx;
        this.dialog = true;
      }
    },
    openDialog(idx) {
      this.activeSubmissionIdx = idx;
      this.dialog = true;
      this.updateUrlParams();
    },
    prev(rating) {
      if (rating) this.updateRating(rating);
      this.activeSubmissionIdx =
        (this.activeSubmissionIdx + this.items.length - 1) % this.items.length;
      this.updateUrlParams();
    },
    next(rating) {
      if (rating) this.updateRating(rating);
      this.activeSubmissionIdx =
        (this.activeSubmissionIdx + 1) % this.items.length;
      this.updateUrlParams();
    },
    updateUrlParams() {
      const urlParams = {
        submission: this.items[this.activeSubmissionIdx].id,
      };
      this.$router.replace({
        path: this.$route.path,
        query: urlParams,
      });
    },
    async cancel() {
      if (this.reviewHasUnsavedChanges) {
        const confirmed = await this.$confirm({
          title: "Please save your work below, or changes will be lost.",
        });
        if (!confirmed) return;
      }
      this.dialog = false;
      this.activeSubmissionIdx = 0;
      this.reviewHasUnsavedChanges = false;

      this.$router.push({
        path: this.$route.path,
        query: undefined,
      });
    },
    updateRating(rating) {
      const submission = this.items[this.activeSubmissionIdx];
      submission.my_rating = rating;
    },
    clearOrder() {
      this.listOptions.order_by = null;
      this.listOptions.order_direction = null;
    },
  },
};
