import { render, staticRenderFns } from "./WorkAssignmentSelector.vue?vue&type=template&id=460384cf&scoped=true&"
import script from "./WorkAssignmentSelector.vue?vue&type=script&lang=js&"
export * from "./WorkAssignmentSelector.vue?vue&type=script&lang=js&"
import style0 from "./WorkAssignmentSelector.vue?vue&type=style&index=0&id=460384cf&scoped=true&lang=scss&"
import style1 from "./WorkAssignmentSelector.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460384cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VIcon,VListItemContent,VTooltip})
