<template>
  <div>
    <v-tooltip top class="ractions hint-menu">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" style="display: flex">
          <div>{{ reviewer.first_name }}</div>
          <v-overlay :value="menu" @click.native.stop="menu = false" />
          <v-menu
            content-class="hint-menu__menu"
            right
            :offset-y="false"
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="menu"
            light
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="reviewer.review_status == 'completed'"
                color="#a7b7c0"
                size="16"
                class="ractions__lock-btn"
                style=""
                @click="menu = true"
                v-bind="attrs"
                v-on="on"
                >$lock</v-icon
              >
            </template>

            <v-card class="hint-menu__menu__content" width="200">
              <v-btn
                class="dialog-close"
                icon
                @click="menu = false"
                color="black"
              >
                <v-icon size="20">$close</v-icon>
              </v-btn>
              <div class="hint-menu__menu__main">
                <div>Unlock Reviewer’s Scorecard?</div>
                <v-btn @click="unlock()" color="primary" elevation="0" x-large>
                  Unlock
                </v-btn>
              </div>
            </v-card>
          </v-menu>
        </div>
      </template>
      {{ reviewer.display_name }}
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    reviewer: Object,
    submissionForm: Object,
  },
  data() {
    return { menu: false };
  },
  methods: {
    async unlock() {
      try {
        const response = await this.$api.SubmissionForm.unlockScoresheet({
          reviewer_id: this.reviewer.id,
          submission_form_id: this.submissionForm.id,
        });
        if (response.status == 200) {
          this.menu = false;
          this.$emit("refresh");
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/hint-menu.scss";

.ractions {
  &__lock-btn {
    margin-left: 0.5em;
    &::v-deep > svg {
      margin-bottom: -0.4em;
    }
  }
}
</style>
