var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-page"},[_c('router-link',{staticClass:"back-btn",attrs:{"to":"/for-review"}},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v("$arrow_left")])],1),(_vm.submissionForm && _vm.list)?_c('div',[_c('div',{staticClass:"review-page__header"},[_c('div',{staticClass:"page-title"},[_vm._v(" My Reviews"),_c('br'),_c('a',{attrs:{"href":this.submissionForm.opp_url}},[_vm._v(_vm._s(_vm.submissionForm.project_code || _vm.submissionForm.title)+" Submissions")])]),_c('div',[_c('form',{attrs:{"method":"post","action":"/api/v1/profile_side/submission_forms/review_submissions_csv","target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"id"},domProps:{"value":_vm.submissionForm.id}}),_c('input',{attrs:{"type":"hidden","name":"token"},domProps:{"value":_vm.$store.getters['auth/token']}}),_c('input',{attrs:{"type":"hidden","name":"all","value":"true"}}),_vm._l((Object.entries(_vm.listParams)),function(item){return _c('input',{key:("listParams-" + (item[0])),attrs:{"type":"hidden","name":item[0]},domProps:{"value":item[1]}})}),_c('v-btn',{staticClass:"review-page__csv-btn",attrs:{"plain":"","type":"submit","elevation":"0"}},[_vm._v("Download as CSV")])],2)])]),_c(_vm.submissionForm.project_type == 'gig'
          ? 'GigSubmissionsReviewTable'
          : _vm.submissionForm.rating_type == 'simple'
          ? 'SubmissionsReviewTable'
          : 'MultiCategorySubmissionsReviewTable',{tag:"component",class:{ 'table-loading': _vm.loadingList },attrs:{"submissionForm":_vm.submissionForm,"items":_vm.list,"listOptions":_vm.listOptions,"reloadKey":_vm.reloadKey},on:{"orderUpdate":function($event){return _vm.orderUpdate($event)},"refresh":function($event){return _vm.refresh()}}}),_c('div',{staticClass:"review-page__bottom-actions"},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.submitScoresheetAvailable},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"review-page__submit-scoresheet-btn",class:{
              'review-page__submit-scoresheet-btn--disabled':
                !_vm.submitScoresheetAvailable,
            },attrs:{"x-large":"","color":"primary","elevation":"0"},on:{"click":_vm.openSubmitScoresheetDialog}},'v-btn',attrs,false),on),[_vm._v("Submit Your Scoresheet")])]}}],null,false,1645167718)},[(this.submissionForm.state == 'submitted')?_c('span',[_vm._v("You cannot submit your scoresheet until after submissions have closed.")]):_vm._e(),(this.submissionForm.my_review_status == 'completed')?_c('span',[_vm._v("You have already submitted your scoresheet.")]):_vm._e(),(!_vm.allSubmissionsReviewed)?_c('span',[_vm._v("Some submissions have not been rated.")]):_vm._e()]),_c('router-link',{staticClass:"back-btn--bottom",attrs:{"to":"/for-review"}},[_c('div',[_c('v-icon',{attrs:{"size":"50","color":"#4282FF"}},[_vm._v("$arrow_left")])],1),_c('div',[_vm._v("Back to For Review")])])],1),_c('AreYouSureDialog',{attrs:{"dialog":_vm.submitScoresheetDialog},on:{"yes":_vm.submitScoresheet,"no":function($event){_vm.submitScoresheetDialog = false}}},[_c('p',[_vm._v("Are you sure?")]),_c('p',{staticStyle:{"font-weight":"400"}},[_vm._v(" Once you submit your scoresheet, you won’t be able to make any further changes to your submission ratings. ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }