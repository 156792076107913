<template>
  <div class="rating-simple">
    <div class="rating-simple__label">
      {{ projectType == "gig" ? "Gig Submission" : "Rating" }}
    </div>
    <Select
      placeholder="Select"
      :items="ratings"
      :value="localValue"
      class="rating-simple__select"
      light
      @input="
        localValue = $event;
        $emit('input', localValue);
      "
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    projectType: String,
    disabled: Boolean,
  },
  data() {
    return {
      ratings: [1, 2, 3, 4, 5],
      localValue: this.value,
    };
  },
};
</script>

<style scoped lang="scss">
.rating-simple {
  padding: 30px 30px 0;
  &__select {
    max-width: 276px;
    border: solid 2px black;
    &::v-deep fieldset {
      border-width: 0;
    }
  }
  &__label {
    font-weight: 700;
    color: #e12d1b;
  }
}
</style>
