<template>
  <tr class="payments-row" v-if="item">
    <td :class="{ 'review-needed': !item.my_rating }">
      {{ item.user.display_name }}
    </td>
    <SubmissionsReviewTableCell
      v-for="field in previewFields"
      :key="field.uuid"
      :field="field"
      :response="responseFromField(field)"
    />
    <td class="center-align">
      <div v-if="item.my_rating" class="rating-points">
        {{ item.my_rating.rating }}
      </div>
      <span v-else class="payments-row__pending">Pending</span>
    </td>
    <td>
      <div class="td-action">
        <v-btn icon @click="$emit('openDialog')">
          <v-icon size="35">$arrow_right</v-icon>
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import SubmissionsReviewTableCell from "./SubmissionsReviewTableCell";

export default {
  components: { SubmissionsReviewTableCell },
  props: {
    item: Object,
    submissionForm: Object,
  },
  methods: {
    responseFromField(field) {
      return this.item.formatted_data.find(
        (itemField) => itemField.uuid == field.uuid
      );
    },
  },
  computed: {
    previewFields() {
      return this.submissionForm.submission_form.filter(
        (field) => field.review_preview
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.review-needed {
  font-weight: bold;
  color: var(--v-primary-base);
}
.rating-points {
  font-weight: bold;
}
</style>
